import React from 'react';
import logo from './logo_ub.svg';
import logo_rub from './logo_rub.svg';
import './App.css';
import {CounterServiceApi} from "./api";

interface State {
    occupancy: number;
    isMaximum: boolean;
}

class App extends React.Component<{},State> {
  
  maximum: number = (process.env.REACT_APP_MAX_OCCUPANCY === null || process.env.REACT_APP_MAX_OCCUPANCY === undefined) ? 480 : Number(process.env.REACT_APP_MAX_OCCUPANCY);

  constructor(){
    super({});
    this.state = {occupancy: 0, isMaximum: false}
  }

  componentDidMount(){

    this.getOccupancy();

    setInterval(async ()=>{
      this.getOccupancy();
    },60000)
  }

  async getOccupancy(){
    const counterApi = new CounterServiceApi();
    const occupancy: number = await counterApi.getOccupancy();
    this.setState({occupancy: occupancy ? occupancy : 0, isMaximum: occupancy <= this.maximum ? false : true});
  }

  render(){
    return (
      <div className={this.state.isMaximum ? 'red' : 'green'}>
        <div className="topleft">
          <img className="logo" src={logo_rub} alt="Logo" />
          <img className="logo" src={logo} alt="Logo" />
        </div>
        <div className="App">
          {this.state.isMaximum ?  <div className="message">Kein Zutritt</div> : <div className="longMessage">Dringende Empfehlung zum Maskentragen in der UB</div>}
          {this.state.isMaximum ?  <div className="noEntryText">Maximale Anzahl Besucher:innen erreicht</div> : ""}
        </div>
      </div>
    );
  }
}

export default App;
